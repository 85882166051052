.css-typing p {
    /*font-family: "Courier";*/
    /*font-size: 14px;*/
    white-space: nowrap;
    overflow: hidden;
    color: rgb(231, 42, 75)
}

.css-typing p {
    width: 100%;
    opacity: 0;
    -webkit-animation: type2 2s infinite;
    animation: type2 2s infinite;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-direction: alternate;
    /*-webkit-animation-delay: 2s;*/
    /*animation-delay: 2s;*/
}

@keyframes type2 {
    0% {
        width: 0;
        border-right: .15em solid transparent;
    }
    10% {
        opacity: 1;
        border-right: .15em solid rgb(231, 42, 75);
    }
    60% {
        border-right: .15em solid rgb(231, 42, 75);
    }
    100% {
        width: 100%;
        opacity: 1;
        border-right: .15em solid transparent;
    }
}

@-webkit-keyframes type2 {
    0% {
        width: 0;
        border-right: .15em solid transparent;
    }
    10% {
        opacity: 1;
        border-right: .15em solid rgb(231, 42, 75);
    }
    60% {
        border-right: .15em solid rgb(231, 42, 75);
    }
    100% {
        width: 100%;
        opacity: 1;
        border-right: .15em solid transparent;
    }
}
